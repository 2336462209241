import { Link } from 'gatsby'
import React from 'react'

const Pagination = ({ pageContext }) => {

	return (
		<nav className="pagination-block">
			<ul className="pagination">

				{pageContext.previousPagePath ?
					<li className="prev btn">
						<a href={pageContext.previousPagePath}><span className="screenreader-text">Previous</span></a>
					</li>
					:
					<li className="prev btn disabled">
						<button disabled><span className="screenreader-text">Previous</span></button>
					</li>
				}

				{[...Array(pageContext.numberOfPages)].map((item, i) => {

					let active = false
					let url = '/blog'
					const humanPage = i + 1
					if (humanPage === pageContext.humanPageNumber) {
						active = true
					}

					if (humanPage !== 1) {
						url = '/blog/' + humanPage
					}

					if (active) {
						return (
							<li className="current" key={i}>
								<span className="current">{pageContext.humanPageNumber}</span>
							</li>
						)
					} else {
						return (
							<li key={i}>
								<Link to={url}>{humanPage}</Link>
							</li>
						)
					}
				})}

				{pageContext.nextPagePath ?
					<li className="next btn">
						<Link to={pageContext.nextPagePath}>
							<span className="screenreader-text">Next</span>
						</Link>
					</li>
					:
					<li className="next btn disabled">
						<button disabled>
							<span className="screenreader-text">Next</span>
						</button>
					</li>
				}

			</ul>
		</nav>
	)
}

export default Pagination
