import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import PageTitle from "../components/PageTitle"
import Seo from "../components/seo"
import BlogItem from "../components/BlogItem"
import BlogNewsletter from '../components/Sections/BlogNewsletter'
import Pagination from "../components/Pagination"

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allPrismicBlogPost(
      sort: { fields: [data___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          data {
            date(formatString: "D MMMM, YYYY")
            image {
              url
            }
            summary {
              text
            }
            title {
              text
            }
          }
          uid
        }
      }
    }
    prismicBlogPage {
      data {
        meta_description {
          text
        }
        meta_title {
          text
        }
        page_title {
          text
        }
        page_subtitle {
          text
        }
        newsletter_title {
          text
        }
        newsletter_subtitle {
          text
        }
        newsletter_content {
          text
        }
        newsletter_image {
          url
        }
      }
    }
  }
`

const BlogList = props => {
  if (!props) return null
  const posts = props.data.allPrismicBlogPost.edges
  const page = props.data.prismicBlogPage.data

  return (
    <Layout>
      <Seo title={page.meta_title[0].text} description={page.meta_description[0].text} />
      <PageTitle subtitle={page.page_subtitle[0].text} title={page.page_title[0].text} />

      <div itemScope itemType="http://schema.org/Blog">

        <section className="container blog blog--featured">
          <div className="container__inner blog__inner">
            <div className="blog__featured">
              <div className="blog__featured-container">
                <BlogItem post={posts[0].node} sizes="(min-width: 45em) 50vw, 98vw" classes="blog__list-link"></BlogItem>
              </div>
            </div>
            { posts.length > 1 ?
              <ul className="blog__list">
                {posts.slice(1, 7).map((post, i) => {
                  return (
                    <li className="blog__list-item" key={i}>
                      <BlogItem post={post.node} sizes="(min-width: 45em) 25vw, 50vw" classes="blog__list-link"></BlogItem>
                    </li>
                  )
                })}
              </ul>
            : null }
          </div>
        </section>
        
        <BlogNewsletter image={page.newsletter_image.url} title={page.newsletter_title[0].text} subtitle={page.newsletter_subtitle[0].text} content={page.newsletter_content[0].text} />

        { posts.length > 6 ?
          <section className="container blog">
            <div className="container__inner blog__inner">
              <ul className="blog__list">
                {posts.slice(7, 14).map((post, i) => {
                  return (
                  <li className="blog__list-item" key={i}>
                    <BlogItem post={post.node} sizes="(min-width: 45em) 25vw, 50vw" classes="blog__list-link"></BlogItem>
                  </li>
                  )
                })}
              </ul>
            </div>
          </section>
        : null }

      <Pagination pageContext={props.pageContext} />

      </div>
    </Layout>
  )
}

export default BlogList