import React from 'react'

const PageTitle  = ({subtitle, title}) => {
  return(
    <section className="container page-title">
      <div className="container__inner page-title__inner">
        <h6 className="page-title__subtitle">{subtitle}</h6>
        <h1 className="page-title__title">{title}</h1>
      </div>
    </section>
  )
}

export default PageTitle;