import { Link } from 'gatsby';
import React from 'react'
import Image from './Image';

const BlogItem  = ({post, sizes, classes}) => {

  let linkClasses = 'blog-item '

  if(!sizes){
    sizes = '50vw'
  }

  if(classes){
    linkClasses += classes
  }

  return(
    <Link to={`/blog/${post.uid}`} className={linkClasses} itemScope itemType="http://schema.org/BlogPosting">
      <Image src={post.data.image.url} classes="blog-item__image objFit" sizes={sizes}></Image>
      <div className="blog-item__content">
        <time className="blog-item__content-date" itemProp="dateCreated datePublished dateModified">{post.data.date}</time>
        <h4 className="blog-item__content-title" itemProp="headline name mainEntityOfPage">{ post.data.title[0].text }</h4>
        <p className="blog-item__content-summary" itemProp="description">
          { post.data.summary[0].text}
        </p>
      </div>
    </Link>
  )
}

export default BlogItem;