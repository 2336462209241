import React from 'react'
import Image from '../Image';
import Newsletter from '../Newsletter';

const BlogNewsletter  = ({title, subtitle, content, image}) => {
  return(
    <section className="container blog-newsletter">
    <div className="container__inner blog-newsletter__inner">
      <div className="blog-newsletter__image-container">
        <Image src={image} sizes="(min-width: 45em) 50vw, 98vw" classes="blog-newsletter__image objFit"></Image>
      </div>
      <div className="blog-newsletter__content">
        <Newsletter classes="blog-newsletter__content-newsletter" subtitle={subtitle} title={title} intro={content}></Newsletter>
      </div>
    </div>
  </section>
  )
}

export default BlogNewsletter;