import React from 'react'

const Image  = ({src, alt, sizes, classes}) => {

  let className = 'image '

  if(classes){
    className += classes
  }

  if(!sizes){
    sizes = '100vw'
  }

  return(
    <figure className={className}>
      <picture className="image__image">
        <img src={src}
            alt={alt}
            sizes={sizes}
            crossOrigin="anonymous" />
      </picture>
    </figure>
  )
}

export default Image;